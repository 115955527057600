import {BuilderWrapper} from "./styles"
import TextWithImage from "./Content/TextWithImage"
import PlainText from "./Content/PlainText"
import RichText from "./Content/RichText"
import StepsContainer from "./Content/StepsContainer"
import GridContainer from "./Grid/GridContainer"
import ButtonsGroup from "./Buttons/ButtonsGroup"
import StrapiField from "./Inputs/StrapiField"
import StrapiEnumField from "./Inputs/StrapiEnumField"
import StrapiDateField from "./Inputs/StrapiDateField"
import StrapiFileField from "./Inputs/StrapiFileField"

const Builder = ({content,emit, onChange}) => {
    const steps = (()=>{
        const index = (content ?? []).findIndex(({__component})=>__component=='content.steps-group')
        if (index >= 0){
            return (content ?? [])[index]
        }
        return null
    })()
    return (
        <>
        <BuilderWrapper>
            {
                (content ?? []).map((block)=>{
                    if (block.__component){
                        switch(block.__component){
                            case 'content.text-with-image':
                                return (
                                    <TextWithImage attr={block} emit={emit} steps={steps?.Steps}/>
                                )
                            case 'content.steps-group':
                                return (
                                    <StepsContainer attr={block} emit={emit}/>
                                )
                            case 'content.plain-text':
                                return (
                                    <PlainText attr={block} />
                                )
                            case 'content.rich-text':
                                return (
                                    <RichText attr={block} />
                                )
                            case 'grid.grid-container':
                                return (
                                    <GridContainer attr={block} />
                                )
                            case 'buttons.buttons-group':
                                return (
                                    <ButtonsGroup attr={block} emit={emit}/>
                                )
                            case 'inputs.text':
                                return (
                                    <StrapiField attr={block} onChange={onChange}/>
                                )
                            case 'inputs.enum':
                                return (
                                    <StrapiEnumField attr={block} onChange={onChange} />
                                )
                            case 'inputs.date-field':
                                return (
                                    <StrapiDateField attr={block} onChange={onChange} />
                                )
                            case 'inputs.file-field':
                                return (
                                    <StrapiFileField attr={block} onChange={onChange} />
                                )
                        }
                    }
                })
            }
        </BuilderWrapper>
        </>
    )
}

export default Builder