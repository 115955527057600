import styled from "styled-components";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const ContactUsWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 24px;
  max-width: 400px;
  margin: 0px auto;
`;
export const ContactUsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 36px 36px 0px;
  width: 100%;
  gap: 12px;
`;
export const ContactUsTitle = styled.div`
  font-family: SF Pro Display;
  font-size: 44px;
  font-weight: 700;
  line-height: 52.51px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: rgba(18, 18, 18, 1);
  width: 100%;
`;
export const ContactUsClose = styled.div`
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before,
  &:after {
    content: "";
    display: block;
    width: 16px;
    height: 2px;
    background: #000;
    position: absolute;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const ContactUsForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 36px 36px;
  width: 100%;
`;
export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const Input = styled.input`
  padding: 15px;
  width: 100%;
  border: 1px solid gray;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  outline: none;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
`;
export const Textarea = styled.textarea`
  padding: 15px;
  width: 100%;
  border: 1px solid gray;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  outline: none;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
  resize: vertical;
  min-height: 90px;
  max-height: 190px;
`;
export const SendButton = styled(Button)`
  border-radius: 12px !important;
  font-family: "SF Pro Text" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: normal !important;
  text-align: center !important;
  box-shadow: none !important;
  text-transform: none !important;
  margin: 0px !important;
  background-color: rgba(252, 238, 221, 1) !important;
  color: rgba(255, 147, 30, 1) !important;
  padding: 14px 24px !important;
  width: 100%;

  @media screen and (max-width: 899px) {
    padding: 12px !important;
  }

  @media screen and (max-width: 346px) {
    margin-bottom: 10px !important;
  }
`;
