import {
  InputContainerWrapper,
  InputLabelWrapper,
  Input,
  Label,
  InputOverlay,
  Placeholder,
  Required,
  InputContainer,
} from "./styles";
import React, { useEffect, useState } from "react";

const StrapiDateField = ({ attr, onChange }) => {
  const [initial, setInitial] = useState("");

  const handleChange = (event) => {
    let value = event.target?.value ?? "";
    if (attr.Type == "Number") {
      value = value.replace(/[^0-9\.]/g, "");
    }
    setInitial(value);
    if (typeof onChange == "function") {
      onChange(attr, value);
    }
  };

  return (
    <InputContainerWrapper>
      <InputLabelWrapper>
        <Label>{attr.Title}</Label>
        {attr.Required ? <Required>*</Required> : []}
      </InputLabelWrapper>
      <InputContainer>
        <Input
          type="date"
          name=""
          id=""
          placeholder={attr.Title}
          value={initial}
          onChange={handleChange}
        />
      </InputContainer>
    </InputContainerWrapper>
  );
};

export default StrapiDateField;
