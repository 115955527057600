import {
  InputContainer,
  Input,
  InputOverlay,
  Placeholder,
  Required,
  EnumContainer,
  InputContainerWrapper,
  EnumList,
  EnumOption
} from "./styles"
import React, { useEffect, useState, useMemo } from "react";
import {makeId} from "../../../../helpers/functions"

const StrapiEnumField = ({attr, onChange}) => {
    const [initial,setInitial] = useState("")
    const [id,setId] = useState(makeId())
    const [optionsVisible,setOptionsVisible] = useState(false)

    const label = useMemo(()=>{
        const index = (attr.Options ?? []).findIndex(({Key})=>Key==initial)
        if (index >= 0){
            if (attr.Options[index]?.Label){
                return [attr.Options[index].Label]
            }
            return []
        }
        return []
    },[attr.Options,initial])

    useEffect(()=>{
        document.addEventListener("click", handleBlur)
    })

    const handleClick = ()=>{
        setOptionsVisible(true)
    }

    const handleBlur = (event)=>{
        const target = event.target
        if (target.closest(`[id='${id}']`)){
            return false
        }
        setOptionsVisible(false)
    }

    const selectOption = (option)=>{
        setInitial(option.Key)
        if (typeof onChange == "function"){
            onChange(attr,option.Key)
        }
        setOptionsVisible(false)
    }

    return (
        <InputContainerWrapper id={id}>
            <InputContainer onClick={handleClick}>
                <EnumContainer hasValue={label.length>0}>{label.length > 0 ? label : "-"}</EnumContainer>
                <InputOverlay>
                    {!initial ? <Placeholder>{attr.Title}</Placeholder>  : []}
                    {!initial && attr.Required ? <Required>*</Required> : []}
                </InputOverlay>
            </InputContainer>
            {optionsVisible ? 
            <EnumList>
                { attr.Options.map((option)=>{
                    return <EnumOption onClick={()=>{
                        selectOption(option)
                    }}>{option.Label}</EnumOption>
                })}
            </EnumList> 
            : []}
        </InputContainerWrapper>
    )
}

export default StrapiEnumField