import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  z-index: 0;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
`;
export const InputContainerWrapper = styled.div`
  position: relative;
  width: 100%;
`
export const Input = styled.input`
  padding: 15px;
  padding-top: ${(prop)=>prop.hasValue ? '20px' : '15px'};
  padding-bottom: ${(prop)=>prop.hasValue ? '10px' : '15px'};
  width: 100%;
  outline: none;
  z-index: 1;
  background: transparent;
  outline: none;
  border: 0px;
  box-shadow: none;
`;
export const Textarea = styled.textarea`
  padding: 0px 15px;
  margin-top: ${(prop)=>prop.hasValue ? '20px' : '15px'};
  margin-bottom: ${(prop)=>prop.hasValue ? '5px' : '15px'};
  width: 100%;
  outline: none;
  z-index: 1;
  background: transparent;
  outline: none;
  border: 0px;
  box-shadow: none;
  resize: vertical;
  max-height: 300px;
  min-height: 80px;
`;
export const InputOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom:0;
    left:0;
    padding: 15px;
    display: flex;
    flex-direction: row;
    gap: ${(prop)=>prop.hasValue ? '4px' : '6px'};
    align-items: flex-start;
    justify-content: flex-start;
    cursor: text;
    z-index: -1;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: ${(prop)=>prop.hasValue ? '10px' : '14px'};
    line-height: 17px;
    transform: translate(${(prop)=>prop.hasValue ? '0px, -10px' : '0px, 0px'});
    
`
export const InputLabelWrapper = styled.div`
  padding: 6px 0px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  cursor: text;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`
export const Label = styled.span`
  color: rgba(18, 18, 18, 1);
  font-weight: bold;
`
export const Placeholder = styled.span`
    color: rgba(136, 136, 136, 1);
`
export const Required = styled.span`
    color: rgba(255, 86, 86, 1);
`
export const EnumContainer = styled.div`
  padding: 15px;
  width: 100%;
  outline: none;
  z-index: 1;
  background: transparent;
  outline: none;
  border: 0px;
  box-shadow: none;
  opacity: ${(props)=>props.hasValue?'1':'0'}
`
export const EnumList = styled.div`
  width:100%;
  background: #fff;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
`
export const EnumOption = styled.div`
  padding: 6px;
  cursor: pointer;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;
`
export const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  align-items: flex-start;
  justify-content: flex-start;
`
export const Dropzone = styled.div`
  background: rgba(255, 147, 30, 0.04);
  width:100%;
  border-radius: 12px;
  padding: 24px;
  border: 1px dashed rgba(255, 147, 30, 0.24);
  color: rgba(41, 41, 41, 1);
  gap: 12px;
  display: flex;
  flex-direction: column;
  width:100%;
  align-items: center;
  justify-content: center;
`
export const DropzoneIcon = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DropzoneLabel = styled.span`
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(41, 41, 41, 1);
  text-align: center;
  a{
    font-weight: bold;
    color: rgba(255, 147, 30, 1);
    cursor: pointer;
  }
`
export const AttachmentHelper = styled.div`
  opacity: 0;
  height: 0px;
  visibility: hidden;
`

export const AttachmentsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 6px;
`

export const AttachmentsItem = styled.div`
  display: grid;
  padding: 10px 12px;
  border: 1px solid rgba(242, 243, 244, 1);
  border-radius: 12px;
  grid-template-columns: 38px 1fr 20px;
  align-items: flex-start;
  width: 100%;
  grid-gap: 16px;
`

export const AttachmentsItemIcon = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 8px;
  background: rgba(68, 137, 241, 0.08);
  color: rgba(71, 155, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: SF Pro Text;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`
export const AttachmentsItemDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
`
export const AttachmentsItemFileName = styled.span`
  color: rgba(41, 41, 41, 1);
  font-size: 14px;
  font-weight: bold;
`
export const AttachmentsItemRemove = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:before,&:after{
    content: "";
    display: block;
    position: absolute;
    width: 2px;
    height: 12px;
    background: rgba(143, 154, 168, 1);
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
`