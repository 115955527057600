import {
  InputContainer,
  Input,
  Textarea,
  InputOverlay,
  Placeholder,
  Required
} from "./styles"
import React, { useEffect, useState} from "react";

const StrapiField = ({attr, onChange}) => {
    const [initial,setInitial] = useState("")
    
    const handleChange = (event)=>{
        let value = event.target?.value ?? ""
        if (attr.Type == 'Number'){
            value = value.replace(/[^0-9\.]/g, "");
        }
        setInitial(value)
        if (typeof onChange == "function"){
            onChange(attr,value)
        }
    }
    let Field = Input
    if (attr.Type?.toLowerCase() == "textarea"){
        Field = Textarea
    }

    return (
        <InputContainer>
            <Field
                type="text"
                name=""
                id=""
                value={initial}
                onChange={handleChange}
                hasValue={initial.length>0}
            />
            <InputOverlay hasValue={initial.length>0}>
                {attr.Title ? <Placeholder hasValue={initial.length>0}>{attr.Title}</Placeholder>  : []}
                {attr.Title && attr.Required ? <Required hasValue={initial.length>0}>*</Required> : []}
            </InputOverlay>
        </InputContainer>
    )
}

export default StrapiField