import { useMemo } from "react";
import {
    InputContainerWrapper,
  Input,
  InputOverlay,
  InputLabelWrapper,
  Label,
  Required,
  AttachmentContainer,
  Dropzone,
  DropzoneIcon,
  DropzoneLabel,
  AttachmentHelper,
  AttachmentsList,
  AttachmentsItem,
  AttachmentsItemIcon,
  AttachmentsItemDetails,
  AttachmentsItemFileName,
  AttachmentsItemRemove
} from "./styles"
import React, { useEffect, useState, useRef } from "react";

const StrapiFileField = ({attr, onChange}) => {
    const [initial,setInitial] = useState([])
    const input = useRef(null);

    const browseFiles = (event)=>{
        input.current.click();
    }

    const filetypes = {
        'application/pdf':'PDF'
    }

    const accepts = useMemo(()=>{
        return attr.AllowedTypes.map((type)=>{
            switch (type.Type.toLowerCase()){
                case "all":
                    return "*"
                case "pdf":
                    return ".pdf,application/pdf"
            }
        })
    },[attr.AllowedTypes])

    const handleChange = (event)=>{
        if (event.target.files){
            let f = 0;
            let files = [...initial]
            while(f < event.target.files.length){
                files.push(event.target.files[f])
                f += 1;
            }
            setInitial(files)
            if (typeof onChange == "function"){
                onChange(attr,files)
            }
        }
    }

    const onDrop = (event)=>{
        event.preventDefault();
        if (event.dataTransfer?.files){
            let f = 0;
            let files = [...initial]
            while(f < event.dataTransfer.files.length){
                files.push(event.dataTransfer.files[f])
                f += 1;
            }
            setInitial(files)
            if (typeof onChange == "function"){
                onChange(attr,files)
            }
        }
    }

    const onDragOver = (event)=>{
        event.preventDefault();
    }

    const removeAttachment = (attachment)=>{
        const files = [...initial]
        const index = files.findIndex(({lastModified,size,name})=>{
            return attachment.name==name && attachment.size==size
        })
        if (index >= 0){
            files.splice(index,1)
            setInitial(files)
            if (typeof onChange == "function"){
                onChange(attr,files)
            }
        }
    }
    
    return (
        <InputContainerWrapper>
            <InputLabelWrapper>
                <Label>{attr.Title}</Label>
                {attr.Required ? <Required>*</Required> : []}
            </InputLabelWrapper>
            <AttachmentHelper>
                <input type="file" ref={input} accept={accepts.join(",")} onChange={handleChange}/>
            </AttachmentHelper>
            <AttachmentContainer>
                {initial.length > 0 ? 
                <AttachmentsList>
                    {initial.map((attachment)=>{
                        return (
                        <AttachmentsItem key={attachment.size}>
                            <AttachmentsItemIcon>{filetypes[attachment.type] ?? ''}</AttachmentsItemIcon>
                            <AttachmentsItemDetails>
                                <AttachmentsItemFileName>{attachment.name}</AttachmentsItemFileName>
                            </AttachmentsItemDetails>
                            <AttachmentsItemRemove onClick={()=>{
                                removeAttachment(attachment)
                                }}></AttachmentsItemRemove>
                        </AttachmentsItem>
                        )
                    })}
                </AttachmentsList> : 
                <Dropzone 
                onDrop={onDrop}
                onDragOver={onDragOver}
                >
                    <DropzoneIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M15.7198 3C16.8817 3 17.9268 3.50609 18.6406 4.30991C18.2956 4.2496 17.9385 4.22 17.5798 4.22L14.2796 4.22L13.3628 3H15.7198Z" fill="#FF931E" stroke="#FF931E"/>
                            <path d="M19.8476 7.44559L19.8476 7.4456L19.8511 7.44808C20.8537 8.15786 21.5 9.3182 21.5 10.64V18.08C21.5 20.2439 19.7439 22 17.58 22H6.42C4.25614 22 2.5 20.2439 2.5 18.08V6.92C2.5 4.75614 4.25614 3 6.42 3H8.74C9.50213 3 9.9401 3.12406 10.2501 3.30535C10.5679 3.49123 10.8066 3.76718 11.1294 4.19926L11.1305 4.20069L12.5305 6.06069L12.5312 6.06155C12.5407 6.0741 12.5503 6.08694 12.5601 6.10001C12.6842 6.26525 12.8366 6.46828 13.0611 6.58631C13.309 6.71667 13.5907 6.72 13.86 6.72H17.58C17.9156 6.72 18.2405 6.76407 18.5562 6.85176L18.5635 6.85379L18.5709 6.85561C19.0374 6.97002 19.4687 7.17243 19.8476 7.44559ZM9.6 17.34H14.39C15.0561 17.34 15.59 16.8061 15.59 16.14C15.59 15.4885 15.0608 14.94 14.39 14.94H9.6C8.91989 14.94 8.41 15.498 8.41 16.14C8.41 16.7967 8.92448 17.34 9.6 17.34Z" fill="#FF931E" stroke="#FF931E"/>
                        </svg>
                    </DropzoneIcon>
                    <DropzoneLabel>Drag and drop your files, or <a onClick={browseFiles}>Browse</a></DropzoneLabel>
                </Dropzone>
                }
            </AttachmentContainer>
        </InputContainerWrapper>
    )
}

export default StrapiFileField