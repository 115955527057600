import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContactUsWrapper,
  ContactUsContainer,
  ContactUsHeader,
  ContactUsTitle,
  ContactUsClose,
  ContactUsForm,
  InputContainer,
  Input,
  Textarea,
  SendButton,
  FormHeading,
  SuccessPopup,
  SucessPopupIcon,
  SucessPopupHeading
} from "./styles";
import { ContentWrapper } from "../../Pages/style";
import StrapiBuilder from "../Strapi/Page/Builder";
import { useMemo } from "react";

const JobOpportunityForm = ({ close, submit }) => {
  const [formContent, setFormContent] = useState({});
  const [form, setForm] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [success,setSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    const populate = [
      "Form.Options",
      "Form.AllowedTypes"
    ];
    fetch(
      `${process.env.REACT_APP_CMSURL}/api/career-opportunities-form?populate=${populate.join(
        ","
      )}`
    )
      .then((response) => response.json())
      .then((response) => {
        setFormContent(response?.data ?? {});
      })
      .finally(()=>{
        setLoading(false);
      })
  }, []);

  const onSubmit = () => {
    //Send strapi form
    let data = {}
    Object.keys(form).map((prop)=>{
      //Detect field type
      const index = formContent?.Form.findIndex(({Property})=>Property==prop)
      if (index >= 0){
        if (formContent?.Form[index]?.__component == 'inputs.file-field'){

        }else{
          data[prop] = form[prop]
        }
      }
    })

    setLoading(true);

    const entry = fetch(`${process.env.REACT_APP_CMSURL}/api/job-applications`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {...data},
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        //Uploaded, Upload files
        const requests = Object.keys(form).map((prop)=> {
          return new Promise((resolve,reject)=>{
            const index = formContent?.Form.findIndex(({Property})=>Property==prop)
            if (index >= 0){
              if (formContent?.Form[index]?.__component == 'inputs.file-field'){
                //Upload image
                const formData = new FormData()
                let f = 0;
                while(f < form[prop].length){
                  formData.append("files",form[prop][f])
                  formData.append("ref","api::job-application.job-application")
                  //@MARK: Strapi BE return id + 1
                  formData.append("refId",response.data.id-1)
                  formData.append("field",prop)
                  f += 1;
                }
                //Actually upload files
                return fetch(`${process.env.REACT_APP_CMSURL}/api/upload`, {
                  method: "post",
                  body: formData
                })
                .then((response) => response.json())
                .then((response)=>{
                  return resolve({
                    ids: response.map((attachment)=>attachment.id),
                    field: prop
                  })
                })
              }
            }
            return resolve(null)
          })
        })
        await Promise.all(requests).then((attachments)=>{
            
        })
        setSuccess(true)
      })
      .catch((error) => {
        alert("There is error processing the form. Please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const valid = useMemo(()=>{
    if (formContent.Form){
      if([...formContent?.Form].filter((field)=>{
        //Validate fields
        const prop = field.Property
        if (prop){
          //Check if field required
          if (field.Required && (!form[prop] || form[prop].length==0)){
            return true
          }
          //Check for regex
          if (field.Validation){
            switch(field.Validation.toLowerCase()){
              case "email":
                if (form[prop] && !form[prop].toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                  return true
                }
                break
              case "phone":
                if (form[prop] && !/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(form[prop])){
                  return true
                }
                break
              case "regexp":
                if (form[prop] && field.RegExp){
                  const reg = new RegExp(field.RegExp)
                  if (!reg.test(form[prop])){
                    return true
                  }
                }
                break
            }
          }
          if (field.MinLength){
            if (form[prop] && form[prop] < field.MinLength){
              return true
            }
          }
          if (field.MaxLength){
            if (form[prop] && form[prop] < field.MaxLength){
              return true
            }
          }
        }
        return false
      }).length > 0){
        return false
      }
      return true
    }
    return false
  },[formContent,form])

  const handleAction = (action) => {

  }

  const handleChange = (field,value) => {
    const prop = field.Property
    if (prop){
      let modifiedForm = {...form}
      modifiedForm[prop] = value
      setForm(modifiedForm)
    }
  }

  return (
    <ContactUsWrapper>
      <ContentWrapper>
        <ContactUsContainer>
          <ContactUsHeader>
            <ContactUsTitle>{formContent?.Title}</ContactUsTitle>
            <ContactUsClose onClick={close}></ContactUsClose>
          </ContactUsHeader>
          <FormHeading>
            <StrapiBuilder content={formContent?.Head} emit={handleAction} />
          </FormHeading>
          <ContactUsForm>
            <StrapiBuilder content={formContent?.Form} emit={handleAction} onChange={handleChange}/>
            <SendButton onClick={onSubmit} disabled={!valid || loading}>Submit</SendButton>
          </ContactUsForm>
        </ContactUsContainer>
      </ContentWrapper>
      {success ? <ContactUsWrapper onClick={close}>
        <SuccessPopup>
          <SucessPopupIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="93" height="93" viewBox="0 0 93 93" fill="none">
          <path d="M34.9993 84.8333H57.9994C77.166 84.8333 84.8327 77.1666 84.8327 58V35C84.8327 15.8333 77.166 8.16663 57.9994 8.16663H34.9993C15.8327 8.16663 8.16602 15.8333 8.16602 35V58C8.16602 77.1666 15.8327 84.8333 34.9993 84.8333Z" fill="#23CF72" fill-opacity="0.14" stroke="#23CF72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M30.209 46.4999L41.0573 57.3483L62.7923 35.6516" stroke="#23CF72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </SucessPopupIcon>
          <SucessPopupHeading>Your application has been successfully submitted!</SucessPopupHeading>
          <p>Our team will review your application and reach out if there’s a match</p>
        </SuccessPopup>
      </ContactUsWrapper> : [] } 
    </ContactUsWrapper>
  );
};

export default JobOpportunityForm;
