import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContactUsWrapper,
  ContactUsContainer,
  ContactUsHeader,
  ContactUsTitle,
  ContactUsClose,
  ContactUsForm,
  InputContainer,
  Input,
  Textarea,
  SendButton,
} from "./styles";
import { ContentWrapper } from "../../Pages/style";

const ContactUs = ({ close, submit }) => {
  const { t } = useTranslation();
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChangeName = (event) => {
    setFullName(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const onSubmit = () => {
    if (!fullName || !email) {
      alert("Provide Full name & Email");
      return;
    }
    if (!loading) {
      setLoading(true);
      ///api/applications
      fetch(`${process.env.REACT_APP_CMSURL}/api/applications`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            FullName: fullName,
            Email: email,
            Message: message,
            Type: "Contact Us",
          },
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          submit();
        })
        .catch((error) => {
          alert("There is error processing the form. Please try again");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <ContactUsWrapper>
      <ContentWrapper>
        <ContactUsContainer>
          <ContactUsHeader>
            <ContactUsTitle>Contact Us</ContactUsTitle>
            <ContactUsClose onClick={close}></ContactUsClose>
          </ContactUsHeader>
          <ContactUsForm>
            <InputContainer>
              <Input
                type="text"
                name=""
                id=""
                placeholder="Full Name"
                onChange={handleChangeName}
              />
            </InputContainer>
            <InputContainer>
              <Input
                type="text"
                name=""
                id=""
                placeholder="Email / Phone Number"
                onChange={handleChangeEmail}
              />
            </InputContainer>
            <InputContainer>
              <Textarea
                type="text"
                name=""
                id=""
                placeholder="Tell us a bit about yourself"
                onChange={handleChangeMessage}
              />
            </InputContainer>
            <SendButton onClick={onSubmit}>Send</SendButton>
          </ContactUsForm>
        </ContactUsContainer>
      </ContentWrapper>
    </ContactUsWrapper>
  );
};

export default ContactUs;
